import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { pages } from "utils/navigation";

// TODO: Should remove this as all we need is router.asPath
export function usePath() {
  const router = useRouter();
  const [activePath, setActivePath] = useState(getActivePath(router));

  useEffect(() => {
    const handleRouteChange = (_url, _opts) => {
      setActivePath(getActivePath(router));
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  return activePath;
}

function getActivePath(router) {
  const match = /\/[a-zA-Z\-]*/.exec(router.asPath);
  return match && match[0];
}

export function useHeading() {
  const [heading, setHeading] = useState(null);
  const path = usePath();

  useEffect(() => {
    const currentPage = pages.find((page) => {
      return page.path && path !== "/" && page.path.indexOf(path) === 0;
    });
    const newHeading = currentPage?.title;
    setHeading(newHeading);
  }, [path]);

  return heading;
}
