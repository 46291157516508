import Head from "next/head";
import Header from "components/layout/header";
import Menu from "components/layout/menu";
import Nav from "components/layout/nav";
import Dialog from "components/dialog";
import UnreadNotificationsBadge from "components/layout/unread_notifications_badge";
import Script from "next/script";

import * as userApi from "api/users";

import { increment } from "utils/tracker";
import { CSSTransition } from "react-transition-group";
import { isBrowser } from "utils/next";
import { track } from "utils/tracker";
import clsx from "clsx";
import * as Sentry from "@sentry/browser";

import { useState, useEffect, useRef } from "react";
import { useMounted } from "hooks/react";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { useUser } from "hooks/user";
import { usePath } from "hooks/navigation";
import { useSubscription } from "hooks/subscription";
import { useNotifications } from "hooks/notifications";

declare global {
  interface Window {
    workbox: any;
    mounted: boolean;
    hj: any;
    dataLayer: any;
  }
}

const cookieSettingsPath = "/configuracoes/cookies";
const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

interface LayoutProps {
  title: string;
  description: string;
  canonical?: string;
  noIndex?: boolean;
  children: React.ReactNode;
  disableTransition?: boolean;
}

export default function Layout(props: LayoutProps): JSX.Element {
  const { title, description, canonical, noIndex, disableTransition } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const [shouldLoadScripts, setShouldLoadScripts] = useState(false);
  const loadScriptSchedule = useRef(null);

  const currentPath = usePath();
  const { isLoading: isLoadingSubscription, isActive: hasActiveSubscription } =
    useSubscription();

  useMounted(handleMount, () => {
    clearTimeout(loadScriptSchedule.current);
  });

  const { setNotification } = useNotifications();
  const router = useRouter();
  const { user } = useUser();

  const { data } = useQuery("user", userApi.show, {
    enabled: !!user,
    refetchInterval: 10 * 60 * 1000
  });
  const unreadNotificationsCount = data?.body?.unreadNotificationsCount || 0;
  const unreadNotificationsBadge = (
    <UnreadNotificationsBadge count={unreadNotificationsCount} />
  );

  useEffect(() => {
    const handleRouteChange = (_url: string) => {
      increment("page_views");
      handlePrivacyDialog();
      localStorage.setItem("render_privacy_dialog", "true");
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, handlePrivacyDialog])

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  function handleMount() {
    window.mounted = true;
    handlePrivacyDialog();
    handleThirdPartyScripts();
    trackInstallPrompt();
    trackPwaInstall();
  }

  function handlePrivacyDialog() {
    if (router.pathname === cookieSettingsPath)
      return setShowPrivacyDialog(false);
    if (localStorage.getItem("cookie_preferences")) return;
    if (!localStorage.getItem("render_privacy_dialog")) return;

    setShowPrivacyDialog(true);
  }

  function acceptCookies() {
    const newPreferences = { behaviorTracking: true, marketing: true };
    localStorage.setItem("cookie_preferences", JSON.stringify(newPreferences));
    setShowPrivacyDialog(false);
    loadHotjar();
  }

  function handleThirdPartyScripts() {
    if (localStorage.getItem("cookie_preferences")) loadHotjar();
    if (localStorage.getItem("not_first_visit")) return loadLateScripts();

    document.querySelector("body").addEventListener("scroll", loadLateScripts);
    loadScriptSchedule.current = setTimeout(loadLateScripts, 5000);
  }

  function loadLateScripts() {
    localStorage.setItem("not_first_visit", "true");
    localStorage.setItem("render_privacy_dialog", "true");
    setShouldLoadScripts(true);
  }

  function trackInstallPrompt() {
    window.addEventListener("beforeinstallprompt", (e: any) => {
      track("Install prompted");
      e.userChoice.then((choiceResult) => {
        track("Install prompt result", { outcome: choiceResult.outcome });
      });
    });
  }

  function trackPwaInstall() {
    window.addEventListener("appinstalled", () => {
      setNotification({
        type: "success",
        title: "Aplicativo instalado!",
        text: "Você já pode acessá-lo pela página inicial do dispositivo :)"
      });
      track("Installed PWA");
    });
  }

  function loadHotjar() {
    if (window.hj) return;
    // prettier-ignore
    // @ts-expect-error Third party script
    // eslint-disable-next-line
    (function(h, o, t, j, a, r) { h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) }; h._hjSettings = { hjid: process.env.NEXT_PUBLIC_HOTJAR_ID, hjsv: 6 }; a = o.getElementsByTagName("head")[0]; r = o.createElement("script"); r.async = 1; r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; a.appendChild(r); })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }

  function shouldRenderAds() {
    return (
      !process.env.NEXT_PUBLIC_DISABLE_ADS &&
      process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
      !isLoadingSubscription &&
      !hasActiveSubscription
    );
  }

  return (
    <>
      <Head>
        <title>{title ? `${title} | Vstiba` : "Vstiba"}</title>
        {description && <meta name="description" content={description} />}
        {canonical && (
          <link rel="canonical" href={`https://www.vstiba.com${canonical}`} />
        )}
        {noIndex && <meta name="robots" content="noindex" />}

        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://images.vstiba.com" />

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleAnalyticsId}');
            `
          }}
        ></script>
      </Head>

      {shouldLoadScripts && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
          />

          <Script
            strategy="lazyOnload"
            src="https://js.stripe.com/v3/pricing-table.js"
          />

          {shouldRenderAds() && (
            <Script
              strategy="lazyOnload"
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1914617132698394"
              crossOrigin="anonymous"
            ></Script>
          )}
        </>
      )}

      <Header
        onClick={() => setShowMenu(true)}
        unreadNotificationsBadge={unreadNotificationsBadge}
      />

      <div className="flex">
        <div
          className={clsx("hidden lg:block", {
            "lg:hidden": currentPath === "/"
          })}
        >
          <Menu
            unreadNotificationsBadge={unreadNotificationsBadge}
            unreadNotificationsCount={unreadNotificationsCount}
          />
        </div>

        <div className="lg:hidden">
          <CSSTransition
            appear={true}
            in={showMenu}
            timeout={300}
            classNames="show-right-full"
            unmountOnExit={true}
          >
            <Menu
              unreadNotificationsBadge={unreadNotificationsBadge}
              unreadNotificationsCount={unreadNotificationsCount}
              onClose={() => setShowMenu(false)}
            />
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="xs-show-up"
          in={true}
          appear={isBrowser() ? window.mounted && !disableTransition : false}
          timeout={300}
        >
          <div className="w-full">{props.children}</div>
        </CSSTransition>
      </div>

      <Nav />

      {showPrivacyDialog && (
        <Dialog
          id="privacy-policy"
          title="Aviso de privacidade"
          confirmText="Aceitar"
          cancelText="Gerenciar"
          onConfirm={acceptCookies}
          onCancel={() => router.push("/configuracoes/cookies")}
          noFade={true}
          bottom={true}
        >
          <p className="dialog-text">
            Utilizamos cookies para capturar e analisar eventos de comportamento
            e customizar a sua experiência. Você permite o uso dos cookies do
            navegador ou deseja gerenciar as suas preferências?
          </p>
        </Dialog>
      )}
    </>
  );
}
