import { filterObjByPresent } from "utils";
import { parentTopic, subTopic, normalizeFilters } from "utils/metadata";
import { isBrowser } from "utils/next";

import { Question } from "types/question";
import { Filters } from "types/filters";
import { RequestResponse } from "utils/base_api";
import { QuestionnaireComponentProps } from "components/questionnaire";

export function questionPathFromApiUri(uri: string): string {
  if (!uri) return null;

  const newPath = uri.replace("/api/exam_question", "/questoes");
  return newPath.replace(
    /\/api\/questionnaire_answers\/by_question\/[0-9]+/,
    "/questoes"
  );
}

export function questionPath(question: Question): string {
  const languages = ["ingles", "espanhol"];
  const language = question.topics.find(
    (topic) => languages.indexOf(topic.slug) !== -1
  )?.slug;
  let path = `/questoes/${question.institution.toLowerCase()}/${
    question.year
  }/${question.examNumber}`;
  if (language) path += `/${language}`;
  return path;
}

export function questionnairePath(filters: Filters): string {
  const { institution, year } = normalizeFilters(filters);
  const subject = filters.subject || parentTopic(filters.topics);
  const topic = filters.topic || subTopic(filters.topics);
  let path = "/questoes";
  if (institution) {
    path += `/${institution.toLowerCase()}`;
    if (year) {
      path += `/${year}`;
    }
  } else if (subject) {
    path += `/${subject.slug}`;
    if (topic) {
      path += `/${topic.slug}`;
    }
  }
  return path;
}

export function currentQuestionPath(): string {
  const question = persistedQuestionnaire()?.question;
  if (!question) return null;

  return questionPath(question);
}

export function persistQuestionnaire(
  questionnaire: QuestionnaireComponentProps
): void {
  localStorage.setItem("current_questionnaire", JSON.stringify(questionnaire));
}

export function persistedQuestionnaire(): QuestionnaireComponentProps {
  return (
    (isBrowser() &&
      JSON.parse(localStorage.getItem("current_questionnaire"))) ||
    null
  );
}

interface QuestionnaireCountersProps {
  answeredQuestions: number;
  answeredCount: number;
  totalCount: number;
}

export function questionnaireCounters(
  response: RequestResponse,
  questionnaire = null
): QuestionnaireCountersProps {
  const { answeredQuestions, answeredCount, totalCount } = response.body;
  return {
    answeredQuestions,
    answeredCount: answeredCount || answeredQuestions,
    totalCount: totalCount || questionnaire?.totalCount || 0
  };
}

export function filtersFromResponse(response: RequestResponse): Filters {
  const { institution, year, testDay, topics } =
    response.body?.data || response.body;
  const subject = parentTopic(topics);
  const topic = subTopic(topics);
  const filters = { institution, year, testDay, subject, topic };
  return filterObjByPresent(filters);
}
