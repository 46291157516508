import * as subscriptionsApi from "api/subscriptions";

import { useQuery } from "react-query";

export function useSubscription(): {
  isLoading: boolean;
  isActive: boolean;
} {
  const { data, error, isLoading } = useQuery(
    "subscription",
    isSubscriptionActive,
    { retry: false, staleTime: 60 * 1000 }
  );
  const isActive = !error && data?.active;

  async function isSubscriptionActive() {
    return subscriptionsApi
      .get()
      .then((response) => {
        return { active: response.body.data.active };
      })
      .catch(() => {
        return { active: false };
      });
  }

  return { isLoading, isActive };
}
