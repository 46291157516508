import Link from "next/link";

import clsx from "clsx";
import { humanizedTime, secondsDiff } from "utils/date";
import { track, increment } from "utils/tracker";

import * as notificationsApi from "api/notifications";

import { NotificationT } from "types/notification";

interface NotificationProps {
  notification: NotificationT;
  loading: boolean;
  onRead: () => void;
  onClick: () => void;
}

export default function Notification(props: NotificationProps): JSX.Element {
  const { notification, loading } = props;
  const { onClick, onRead } = props;

  const isPending = notification.url && !notification.seenAt;

  function insertedAtText() {
    if (!notification.insertedAt) return "";

    const secondsSinceInsertion = secondsDiff(
      notification.insertedAt,
      new Date()
    );
    return humanizedTime(secondsSinceInsertion);
  }

  function handleClick() {
    if (!notification.url) return;

    track("Clicked notification", { category: notification.category });
    increment("notifications_seen");
    onClick();

    if (notification.seenAt) return;

    notificationsApi
      .markSeenAt(notification.id, new Date())
      .then((_response) => {
        onRead();
      });
  }

  return (
    <Link href={notification.url || "#"}>
      <a
        className={clsx("fade-loading block py-2 no-underline", {
          loading,
          "cursor-default": !notification.url
        })}
        onClick={handleClick}
      >
        <header className="flex items-start justify-between mb-1">
          <span
            className={clsx("overline", {
              "text-primary-800": isPending
            })}
          >
            {notification.category}
          </span>
          <span className="text-disabled whitespace-nowrap leading-none">
            {insertedAtText()}
          </span>
        </header>

        <span className="content">{notification.content}</span>
      </a>
    </Link>
  );
}
