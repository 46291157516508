import { request } from "utils/api";

export function index(page = 1) {
  return request(`/notifications?page=${page}`, { method: "GET" });
}

export function markSeenAt(id, seenAt) {
  return request(`/notifications/${id}`, {
    method: "PATCH",
    body: { seenAt: seenAt.toISOString() }
  });
}
