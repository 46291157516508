import Link from "next/link";
import { pages } from "utils/navigation";

import { currentQuestionPath } from "utils/questionnaire";
import clsx from "clsx";

import { useState, useEffect } from "react";
import { usePath } from "hooks/navigation";

export default function Nav(): JSX.Element {
  const activePath = usePath();

  const options = [
    { title: "Plano", path: "/plano/tarefas" },
    { title: "Simulado", path: "/questoes" },
    { title: "Aulas", path: "/aulas" },
    { title: "Desempenho", path: "/desempenho" }
  ];
  const items = options.map((option) => {
    const page = pages.find((page) => page.path == option.path);
    return (
      <Item
        {...option}
        key={option.path}
        icon={page.icon}
        path={itemPath(option)}
      />
    );
  });

  function itemPath(item) {
    if (item.title !== "Simulado" || !currentQuestionPath()) return item.path;

    return currentQuestionPath();
  }

  if (activePath === "/") return <div></div>;

  return (
    <nav className="fixed z-30 bottom-0 left-0 flex justify-around p-2 w-full bg-surface md:hidden">
      {items}
    </nav>
  );
}

function Item(props) {
  const { title, icon, path } = props;

  const [isActive, setIsActive] = useState(false);
  const activePath = usePath();

  useEffect(() => {
    if (!path) return;
    const section = /\/[a-z]*/.exec(path)[0];
    setIsActive(activePath == section);
  }, [activePath, path]);

  function activeColor() {
    return isActive ? "text-secondary-600" : "";
  }

  return (
    <Link href={path}>
      <a className="relative flex flex-col items-center justify-between no-underline">
        <div className={activeColor()}>{icon()}</div>
        <span
          className={clsx("mt-[6px] flex items-center text-sm", activeColor())}
        >
          {title}
        </span>
      </a>
    </Link>
  );
}
