import Image from "next/image";
import Link from "next/link";

import { FiMenu } from "react-icons/fi";
import clsx from "clsx";

import { usePath, useHeading } from "hooks/navigation";
import { useUser } from "hooks/user";

interface Props {
  onClick: () => void;
  unreadNotificationsBadge: JSX.Element;
}

export default function Header(props: Props): JSX.Element {
  const { unreadNotificationsBadge } = props;

  const heading = useHeading();
  const activePath = usePath();
  const { user } = useUser();

  const desktopHidden = activePath !== "/";

  return (
    <>
      <header
        className={clsx(
          "border-box relative flex items-center justify-between p-5 w-full h-12 bg-primary-400",
          { "lg:hidden": desktopHidden }
        )}
      >
        <div className="container flex items-center justify-between w-full">
          <div className="flex">
            <Link href={user ? "/plano/tarefas" : "/"}>
              <a className="flex items-center">
                <Image
                  src="/logo-pb.svg"
                  alt="Logo do Vstiba"
                  width={56}
                  height={18}
                />
              </a>
            </Link>

            <ul className="hidden ml-16 lg:flex">
              <Item title="Plano" href="/plano/tarefas" />
              <Item title="Simulado" href="/questoes" />
              <Item title="Aulas" href="/aulas" />
              <Item title="Desempenho" href="/desempenho" />
            </ul>
          </div>

          <h1 className="absolute-center inline-block text-lg font-semibold">
            {heading}
          </h1>

          <div
            className="nav-trigger relative justify-self-end lg:hidden"
            onClick={props.onClick}
          >
            <FiMenu size={22} />
            <div className="absolute -top-1 right-7">
              {unreadNotificationsBadge}
            </div>
          </div>
        </div>
      </header>
      <div className="-mb-24 w-full h-24 bg-primary-300 lg:hidden"></div>
    </>
  );
}

function Item(props) {
  const { title, href } = props;

  return (
    <li className="mr-5">
      <Link href={href}>
        <a className="py-[9px] px-4 no-underline hover:border-b-4 hover:border-primary-500 active:border-primary-700">
          {title}
        </a>
      </Link>
    </li>
  );
}
