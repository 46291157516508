import Loader from "components/loader";

export default function Submit(props) {
  const { loading, className, onClick } = props;
  const disabled = props.disabled || loading;

  return (
    <button onClick={onClick} className={className} data-disabled={disabled}>
      <span className={loading ? "invisible" : ""}>{props.children}</span>
      {loading && (
        <div className="absolute-center">
          <Loader />
        </div>
      )}
    </button>
  );
}
