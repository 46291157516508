import Notification from "./menu_notifications/notification";
import Link from "next/link";
import InfiniteScrollable from "components/behaviors/infinite_scrollable";
import Loader from "components/loader";

import * as notificationsApi from "api/notifications";

import { isBrowser } from "utils/next";
import { Fragment } from "react";
import { notifications as placeholderNotifications } from "utils/menu_notifications/placeholders";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { isPwa } from "utils/browser";

import { useUser } from "hooks/user";
import { useInfiniteQuery } from "react-query";

type MenuNotificationsProps = {
  onClose: () => void;
  onRead: () => void;
};

export default function MenuNotifications(
  props: MenuNotificationsProps
): JSX.Element {
  const { onClose, onRead } = props;

  const { user } = useUser();
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery(
      "notifications",
      ({ pageParam = 1 }) => notificationsApi.index(pageParam),
      {
        getNextPageParam: (lastPage, pages) => {
          return lastPage.body.data.length > 0 ? pages.length + 1 : undefined;
        }
      }
    );
  const pages = data?.pages || [{ body: { data: placeholderNotifications } }];

  return (
    <div className="notifications-wrapper h-full box-border overflow-y-scroll">
      <header className="flex items-center justify-between py-3">
        <div
          onClick={onClose}
          className="notifications-back-btn skip-closable cursor-pointer transform"
        >
          <FiChevronLeft size={22} />
        </div>

        <h5 className="mb-0">Notificações</h5>

        <div className="invisible transform">
          <FiChevronRight size={22} />
        </div>
      </header>

      <div className="notifications my-2 px-2">
        {(!user && (
          <span className="block text-emphasis-medium">
            <Link href="/cadastro">
              <a className="inline-block mr-1" onClick={onClose}>
                Crie uma conta
              </a>
            </Link>
            gratuita e receba notificações de novidades e lembretes dos estudos
            😀
          </span>
        )) || (
          <InfiniteScrollable
            onPaginate={() => fetchNextPage()}
            loading={isFetchingNextPage}
            skipPagination={false}
            elem={isBrowser() && document.querySelector(".application-menu")}
          >
            {pages.map((page, index) => (
              <Fragment key={index}>
                {page.body.data
                  .filter((notification) => {
                    return isPwa() ? !notification.skipPwa : true;
                  })
                  .map((notification) => {
                    return (
                      <div key={notification.id} className="my-2">
                        <Notification
                          notification={notification}
                          onClick={onClose}
                          onRead={onRead}
                          loading={isLoading}
                        />
                      </div>
                    );
                  })}
              </Fragment>
            ))}
          </InfiniteScrollable>
        )}

        {isFetchingNextPage && (
          <div className="flex justify-center mb-2 mt-8">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}
