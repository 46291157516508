import {
  FiGrid,
  FiEdit2,
  FiList,
  FiPieChart,
  FiPlay,
  FiMail,
  FiSmartphone,
  FiBell,
  FiSettings,
  FiAward,
  FiUserPlus,
  FiUser,
  FiUserX
} from "react-icons/fi";
import { track } from "utils/tracker";

export const pages = [
  { title: "Plano de estudos", path: "/plano/tarefas", icon: FiGrid },
  { title: "Simulado", path: "/questoes", icon: FiEdit2 },
  { title: "Meus simulados", path: "/meus-simulados", icon: FiList },
  { title: "Desempenho", path: "/desempenho", icon: FiPieChart },
  { title: "Aulas", path: "/aulas", icon: FiPlay },
  { title: "Contato", path: "/contato", icon: FiMail },
  { title: "Aplicativo", path: "/aplicativo", icon: FiSmartphone },
  { title: "Notificações", icon: FiBell },
  { title: "Configuracões", path: "/configuracoes", icon: FiSettings },
  {
    title: "Remover anúncios",
    path: "/remover-anuncios",
    icon: FiAward,
    onClick: () => track("Clicked to visit Remove ads page")
  },
  {
    title: "Vstiba Total",
    path: "/assinatura",
    icon: FiAward,
    onClick: () => track("Clicked to visit Subscription page")
  },
  { title: "Cadastro", path: "/cadastro", icon: FiUserPlus },
  { title: "Login", path: "/login", icon: FiUser },
  { title: "Sair", icon: FiUserX }
]
