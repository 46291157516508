import dayjs, { Dayjs } from "dayjs";

export function formattedDatetime(datetime: Date | string): string {
  return dayjs(datetime).format("DD/MM/YYYY - HH:mm");
}

export function formattedDate(datetime: Date | string): string {
  return dayjs(datetime).format("DD/MM/YYYY");
}

export function formattedTime(datetime: Date | string): string {
  return dayjs(datetime).format("HH:mm");
}

export function humanizedTime(seconds: number, precise = false): string {
  seconds = Math.round(seconds);
  if (seconds < 60) return `${seconds}s`;

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    const secondsLeft = seconds - minutes * 60;
    if (!precise || secondsLeft === 0) return `${minutes}min`;
    return `${minutes}min${secondsLeft}s`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    const minutesLeft = minutes - hours * 60;
    if (minutes === 0) return `${hours}h`;
    else return `${hours}h${minutesLeft}min`;
  }

  const days = Math.floor(hours / 24);
  if (days >= 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks} sem`;
  }

  if (days == 1) return "1 dia";

  return `${days} dias`;
}

export function secondsDiff(date1: Date | Dayjs, date2: Date | Dayjs): number {
  const end = dayjs(date2);
  return end.diff(date1, "seconds");
}
