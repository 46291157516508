import { isEndOfScroll } from "utils/browser";

import { SyntheticEvent, useEffect } from "react";

interface InfiniteScrollablableProps {
  onPaginate: (event: SyntheticEvent) => void;
  loading: boolean;
  children: React.ReactNode;
  skipPagination?: boolean;
  elem?: HTMLElement;
}

export default function InfiniteScrollablable(
  props: InfiniteScrollablableProps
): JSX.Element {
  const { onPaginate, skipPagination, loading, elem } = props;

  useEffect(() => {
    if (skipPagination) return;

    const body = document.querySelector("body");
    const scrollElem = elem || body;
    scrollElem.addEventListener("scroll", handleScroll);
    return () => {
      scrollElem.removeEventListener("scroll", handleScroll);
    };

    function handleScroll(event) {
      if (!isEndOfScroll(event) || skipPagination || loading) return;

      onPaginate(event);
    }
  }, [onPaginate, skipPagination, elem, loading]);

  return <div>{props.children}</div>;
}
