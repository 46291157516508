import { useRef, useEffect } from "react";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  skip?: () => boolean;
}

export default function Closable(props: Props): JSX.Element {
  const { children, onClose, skip } = props;
  const ref = useRef(null);

  useEffect(() => {
    function ignoreClick(event) {
      if (skip && skip()) return true;

      return (
        event.target.closest(".dialog-bg") ||
        event.target.closest(".dialog-wrapper") ||
        event.target.closest(".notification") ||
        event.target.closest(".select-options") ||
        event.target.closest(".skip-closable") ||
        !event.target.parentNode
      );
    }

    function handleClick(event) {
      if (event.key) {
        const shouldClick = event.key === "Escape" && !ignoreClick(event);
        return shouldClick && onClose && onClose();
      }

      const clickedOutside = ref.current && !ref.current.contains(event.target);
      if (clickedOutside && !ignoreClick(event)) onClose && onClose();
    }

    setTimeout(() => {
      document.addEventListener("click", handleClick);
      window.addEventListener("keydown", handleClick);
    }, 10);
    return () => {
      setTimeout(() => {
        document.removeEventListener("click", handleClick);
        window.removeEventListener("keydown", handleClick);
      }, 50);
    };
  }, [skip, onClose]);

  return <div ref={ref}>{children}</div>;
}
