export const notifications = [
  {
    category: "Plano de estudos",
    content: "Já montou seu plano de estudos? É rápido e gratuito!",
    id: 1,
    inserted_at: "2021-08-06T14:20:17.165655-03:00",
    seen_at: null,
    url: "/plano/editar"
  },
  {
    category: "Boas-vindas",
    content: "Como é bom ter você aqui!",
    id: 2,
    inserted_at: "2021-08-06T14:20:17.135611-03:00",
    seen_at: "2021-08-06T14:20:17-03:00",
    url: null
  },
  {
    category: "Plano de estudos",
    content: "Já montou seu plano de estudos? É rápido e gratuito!",
    id: 3,
    inserted_at: "2021-08-06T14:20:17.165655-03:00",
    seen_at: null,
    url: "/plano/editar"
  },
  {
    category: "Boas-vindas",
    content: "Como é bom ter você aqui!",
    id: 4,
    inserted_at: "2021-08-06T14:20:17.135611-03:00",
    seen_at: "2021-08-06T14:20:17-03:00",
    url: null
  },
  {
    category: "Plano de estudos",
    content: "Já montou seu plano de estudos? É rápido e gratuito!",
    id: 5,
    inserted_at: "2021-08-06T14:20:17.165655-03:00",
    seen_at: null,
    url: "/plano/editar"
  },
  {
    category: "Boas-vindas",
    content: "Como é bom ter você aqui!",
    id: 6,
    inserted_at: "2021-08-06T14:20:17.135611-03:00",
    seen_at: "2021-08-06T14:20:17-03:00",
    url: null
  }
];
